<template>
  <div class="w-80 h-screen">
    <div class="h-full w-full flex justify-center flex-col items-center">
      <div class="text-center mt-10">
        <img
            class="mx-auto w-20"
            :src="logo"
            alt="logo"
        />
        <h4 class="text-xl font-semibold mt-1 mb-12 pb-1">SOPECAM</h4>
      </div>
      <LoaderComponent/>
      <span>Paiement en cours...</span>
    </div>

  </div>
</template>

<script>
import logo from '../assets/logo.png'
import LoaderComponent from "@/components/LoaderComponent";

export default {
  name: "SuccesPayment",
  data(){
    return{
      logo
    }
  },
  components:{
    LoaderComponent
  }
}
</script>

<style scoped>

</style>