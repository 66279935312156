<template>
  <div v-if="data!==null && showLoader===false" class="">
    <div class="bg-white overflow-visible sm:rounded-lg">
      <div class="px-4 py-5 sm:px-6 d-flex">
        <div class="relative">
          <h3 class="text-lg leading-6 font-medium text-gray-900">Renouveller la licence de {{
              this.data.software
            }}</h3>
          <p class="mt-1 max-w-2xl text-sm text-gray-500">Détails relatifs au paiement de votre licence </p>
          <button @click="gotToHome" class="sm:absolute right-0 top-0 bottom-0 underline text-red-500 border-red-500">
            Quitter
          </button>
        </div>
      </div>
      <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div class="sm:col-span-1">
            <dt class="text-sm font-medium text-gray-500">Nom</dt>
            <dd class="mt-1 text-sm text-gray-900">{{ this.data.name }}</dd>
          </div>
          <div class="sm:col-span-1">
            <dt class="text-sm font-medium text-gray-500">Category</dt>
            <dd class="mt-1 text-sm text-gray-900">{{ this.data.category }}</dd>
          </div>
          <div class="sm:col-span-1">
            <dt class="text-sm font-medium text-gray-500">Numero de licence</dt>
            <dd class="mt-1 text-sm  bg-blue-400 p-1.5 rounded text-white">{{ this.data.license_number }}</dd>
          </div>
          <div class="sm:col-span-2">

            <div v-if="isOnPromo" class="mb-2">
              <p class="mb-2 text-blue-500 font-bold text-center">Profiter d'une réduction de 10% en payant la totalité
                du prix</p>
              <img :src="promo" alt="promo badge" class="rounded-md"/>
            </div>
            <P v-if="!hasCreance && !isOnPromo" class="mb-2 text-gray-500 font-bold border-b-2 border-dotted mt-3 border-gray-300 ">MONTANT HT: {{ params.amount_HT }} FCFA</p>

            <br>

            <p v-if="!hasCreance && !isOnPromo && params.with_tva === 1" class="mb-2 text-red-500 font-bold border-b-2 border-dotted mt-3 border-gray-300 ">
              TVA: {{ params.tva_amount }} FCFA</p>

            <br>

            <dt class="text-sm mt-1 font-medium text-gray-500">Montant à payer TTC</dt>
            <div v-bind:class="!creanceMessage ?'flex':''" class="flex-row ">
              <small>{{ creanceMessage }}</small> <br>
              <span
                  v-bind:class="isOnPromo ?'font-extrabold text-5xl text-blue-500 mr-5':'text-2xl text-gray-900'">{{
                  params.amount
                }} FCFA</span>
              <dd v-if="isOnPromo" class="strike">
                <span class="text-sm text-red-500 ">
                 <p class="text-lg font-black text-gray-900">{{ params.amount_TTC }} FCFA</p>

              </span>
              </dd>

            </div>
          </div>
          <div class="sm:col-span-2">
            <dt class="text-sm font-medium text-gray-500">Paiement</dt>
            <dd class="mt-3 text-sm text-gray-900 border border-gray-200 rounded-md divide-gray-200 py-4 px-4">
              <div class="space-y-2 mb-2">
                <label for="name">Nom</label>
                <input
                    class="bg-gray-200 ring-1 ring-purple-400 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                    placeholder="Inserer  votre nom" type="text" v-model="params.payeer_name">
              </div>
              <div class="space-y-2 mb-2">
                <label for="name">Numéro de téléphone</label>
                <input
                    class="bg-gray-200 ring-1 ring-purple-400 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                    placeholder="Numero de télephone" type="number" v-model="params.mobileno">
              </div>
              <div class="space-y-2 mb-2">
                <label for="name">Montant à payer</label>
                <input
                       class="bg-gray-200 ring-1 ring-purple-400 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                       placeholder="Montant à payer" type="number" v-model="params.amount">
              </div>
              <button @click="payment" type="button"
                      class="mt-4 text-white bg-blue-700 w-full hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded text-sm px-5 py-2.5 text-center inline-flex justify-center items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                <span>Payer avec NexahPay</span>
                <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 ml-2 -mr-1" fill="none" viewBox="0 0 24 24"
                     stroke="currentColor" stroke-width="2">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"/>
                </svg>
              </button>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  </div>
  <loader-component v-if="showLoader" :message="'Chargement en cours'"/>
</template>

<script>
import {PaperClipIcon} from '@heroicons/vue/solid'
import LoaderComponent from "@/components/LoaderComponent";
import SuccesPayment from "@/components/SuccesPayment";
import axios from "axios";

export default {
  data() {
    return {
      data: null,
      promo: require('/src/assets/promo.jpg'),
      showLoader: false,
      promoCard: false,
      hasCreance: false,
      creanceMessage: '',
      params: {
        company_id: null,
        payeer_name: null,
        mobileno: null,
        promo_amount: 0,
        tva_amount: 0,
        amount_HT: 0,
        amount: 0,
        amount_TTC:0,
        with_tva:0
      }
    }
  },
  components: {
    PaperClipIcon,
    LoaderComponent
  },
  computed: {
    isOnPromo() {
      return this.promoCard;
    }
  },
  mounted() {
    this.data = JSON.parse(localStorage.getItem('dataLicence'));
    /*cas normal*/
    if (this.data.amount_creance === 0 && this.data.promo_amount === 0 ) {
      this.params.amount = this.data.amount_TTC
      this.params.amount_HT = this.data.amount
      this.params.tva_amount = this.data.amount_tva
      this.params.with_tva = this.data.with_tva
      }
    /*cas de creance*/
    else if(this.data.amount_creance > 0 &&  this.data.promo_amount === 0) {
      this.data.amount = this.data.amount_creance
      this.params.amount = this.data.amount_creance
      this.hasCreance = true
      this.creanceMessage = 'Veuillez s\'il vous plait régler votre créance élevée à'
      this.params.with_tva = this.data.with_tva
    }
    /*Promo*/
    else {
      this.params.promo_amount = this.data.promo_amount
      this.params.amount = this.data.promo_amount
      this.params.amount_TTC=this.data.amount_TTC
      this.promoCard = true
      this.params.tva_amount = this.data.amount_tva
      this.params.with_tva = this.data.with_tva
    }
    // this.promoCard = this.data.promo_amount > 0;
    // if (this.data.promo_amount >= 0) {
    //   this.params.amount = this.data.amount_TTC
    //   console.log(this.params.amount)
    //   this.params.tva_amount = this.data.amount_tva
    //   this.params.promo_amount = this.data.promo_amount
    // } else if (this.data.amount_creance > 0) {
    //   this.data.amount = this.data.amount_creance
    //   this.params.amount = this.data.amount_creance
    //   this.hasCreance = true
    //   this.creanceMessage = 'Veuillez s\'il vous plait régler votre créance élevée à'
    // }else  {
    //   this.params.amount = this.data.amount
    // }
  },
  updated() {
    // if (this.params.amount === this.data.amount){
    //   console.log(this.data.amount)
    //   console.log(this.data.amount)
    //   this.params.promo_amount = this.data.promo_amount;
    // }
    // else{
    //   if (this.params.amount > this.data.amount ){
    //     this.params.amount = this.data.amount;
    //   }
    //   this.params.promo_amount = 0;
    // }
    this.calculateTva()
    this.verifPromo()


  }
  ,
  methods: {
    payment() {
      console.log(this.params.amount)
      console.log(this.data.amount_creance)
      if (this.params.payeer_name !== null && this.params.mobileno !== null && this.params.amount > 100 &&  this.params.amount <= this.data.amount_TTC || this.params.amount <= this.data.amount_creance) {
        console.log('is true')
        this.showLoader = true
        this.params.company_id = this.data.id
        axios.post("https://ms-licence-pay.nexah.net/api/v1/pay", this.params)
            .then(response => {
              console.log(response.data)
              this.$emit('suivant', {component: SuccesPayment})
              localStorage.removeItem('dataLicence');
            }).catch(error => {
          console.log("Une erreur c'est produite")
          console.log(error.message)
          this.showLoader = false
        })
      }else if(this.data.amount_creance === 0  && this.params.amount > this.data.amount_TTC){
        alert('Le montant que vous voulez payer est supérieur au montant total')
      }else if (this.data.amount_creance > 0  && this.params.amount > this.data.amount_creance){
        alert('Le montant que vous voulez payer est supérieur au montant de la creance')
      }
      else {
        console.log('Bad')
        alert('Les données saisies sont incorrectes')
      }
    },

    gotToHome() {
      localStorage.clear();
      window.location.assign("/")
    },
    calculateTva() {
      this.params.tva_amount = Math.ceil(this.params.amount_HT * 0.1925)

    },
    verifPromo(){
      // console.log('OK')
      /*Promo life verification for amount to pay*/
      if (this.params.promo_amount > 0){
          this.promoCard = this.params.amount === this.params.promo_amount
        if (!this.promoCard){
          this.params.amount_HT = this.data.amount
          this.params.tva_amount = this.data.amount_tva
        }
      }
    }
  },
}
</script>

<style>
.strike {
  text-decoration: line-through;
  text-decoration-thickness: 2px;
  color: #404040;
}
</style>