import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Detail from "@/views/Detail";
import welcome from "/src/views/WelcomeImg"
import WelcomeImg from "../views/WelcomeImg";

const routes = [
  // {
  //   path: '/',
  //   name: 'welcome',
  //   component: WelcomeImg
  // },
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/detail',
    name: 'detail',
    component: Detail
  },
  // {
  //   path: '/welcome',
  //   name: 'welcome',
  //   component: WelcomeImg
  // }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
