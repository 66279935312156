<template>
  <div class="md:p-8 md:mx-6 flex flex-col justify-center items-center h-screen ">
    <div class="text-center">
      <img
          class="mx-auto w-56"
          src="https://www.pngitem.com/pimgs/m/535-5355451_payment-success-illustration-png-transparent-png.png"
          alt="logo"
      />
      <h4 class="text-xl font-semibold mt-1 mb-12 pb-1">Renouvellement Licence</h4>
    </div>
    <div class="bg-green-600 p-5 m-2 text-white font-light text-center">
      {{message?message:'Votre paiement a bien été initialiser, veuillez le confirmer sur votre mobile'}}
    </div>
  </div>
</template>

<script>
import logo from '../assets/logo.png'

export default {
  name: "SuccesPayment",
  props:['message'],
  data(){
    return{
      logo
    }
  }
}
</script>

<style scoped>

</style>